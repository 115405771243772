import React from 'react'
import { injectIntl } from 'gatsby-plugin-intl'
import Layout from '../components/layout'
import SEO from '../components/seo'

const HostnameIP = ({ intl }) => (
  <Layout>
    <SEO title={intl.formatMessage({ id: 'navigation.hostnameIp' })} />
    <div
      style={{
        maxWidth: `800px`,
        margin: `0 auto`,
      }}
    >
      <div className='interfaceRow'>
        <div className='half'>IP Address: 192.168.0.100</div>
      </div>
      <div className='interfaceRow'>
        <div className='half'>Current Hostname: SN-20-9999</div>
      </div>
      <form>
        <div className='interfaceRow'>
          <div className='half inputfields'>
            <label htmlFor='newhostname'>New Hostname:</label>
            <input
              className='maxTen'
              type='text'
              placeholder='##-##-####'
              name='newhostname'
              id='newhostname'
            />
          </div>
          <div className='half textRight'>
            <button>Accept Hostname</button>
          </div>
        </div>
      </form>
      <form>
        <div className='interfaceRow'>
          <div className='twoThird inputfields'>
            <label htmlFor='ipaddress'>Modbus Software IP Address:</label>
            <input
              className='maxTen'
              type='text'
              placeholder='192.168.0.222'
              name='ipaddress'
              id='ipaddress'
            />
          </div>
          <div className='third textRight'>
            <button>Accept IP Address</button>
          </div>
        </div>
      </form>
    </div>
  </Layout>
)

export default injectIntl(HostnameIP)
